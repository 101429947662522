@import './_variables';
@import './property-info';

body {
  background-color: map-get($colors, 'bg-color');
  color: map-get($colors, 'text');
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;

  &.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

a {
  text-decoration: underline;
}

.custom-link {
  color: map-get($colors, 'primary');
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: darken(map-get($colors, 'primary'), 5%);
  }
}

a,
.custom-link {
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

/**
 * Menus
 */
.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    padding: 7.5px 0;

    a:not(.support) {
      display: block;
      font-size: 14px;
      padding: 7.5px 10px;
      text-decoration: none;

      &:hover {
        background-color: darken(map-get($colors, 'light'), 5%);
      }
    }

    .support-wrapper {
      border-bottom: 1px solid lighten(map-get($colors, 'border'), 5%);
      font-size: 12px;
      opacity: 0.8;
      padding: 5px 10px 10px;
      margin-bottom: 2.5px;
    }
  }
}

/**
 * Mat select
 */
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: map-get($colors, 'primary');
}

.mat-mdc-option {
  min-height: 40px !important;

  .mdc-list-item__primary-text {
    font-size: 14px !important;
  }
}

.select-placeholder {
  color: map-get($colors, 'text-placeholder') !important;
}

/**
 * Mat checkbox 
 */
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  border-color: map-get($colors, 'border-input');
  border-width: 1px;
  border-radius: 4px;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  background-color: map-get($colors, 'primary') !important;
  border-color: map-get($colors, 'primary') !important;
  border-radius: 4px;
}

/**
  * Ngx Toaster
  */
.toast-container .ngx-toastr {
  padding: 15px 20px;
  width: 300px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: inherit;
  }

  @media (min-width: 400px) {
    width: 320px;
  }

  .toast-message {
    font-size: 14px;
  }

  &.toast-success,
  &.toast-error,
  &.toast-warning,
  &.toast-info {
    background-image: none;
  }

  &.toast-warning {
    background-color: map-get($colors, 'warning');
  }

  &.toast-error {
    background-color: map-get($colors, 'danger');
  }
}

/**
 * Others
 */
.disabled-lightbox {
  bottom: 0;
  cursor: not-allowed;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

/**
 * Spinner
 */
.global-spinner {
  width: 20px;
  height: 20px;
  border: 1.5px solid;
  border-color: #fff transparent #fff transparent;
  border-radius: 50%;
  animation: spin 1.2s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/** 
 * Forms
 */
.input-error-message {
  color: lighten(map-get($colors, 'danger'), 6%);
  font-size: 13px;
  margin: 2px 0 0 2px;
}

/**
 * Mat Dialog
 * Mat Menu
 */
.cdk-overlay-container {
  z-index: 5;
}

.mat-mdc-menu-panel {
  border: 1px solid map-get($colors, 'light');
  box-shadow: $box-shadow !important;
}

/**
 * Global sections
 */
section.default {
  margin: 0;

  &.mt {
    margin-top: 2rem;
  }

  &.mb {
    margin-bottom: 2rem;
  }

  h4.title,
  h5.title {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0 0 0.75rem;
    text-transform: uppercase;
  }
}

/**
  * Dashboard map view
  */
.map-view-wrapper {
  .gm-style-mtc {
    display: none;
  }

  .map-item-wrapper {
    position: relative;

    .price-tag {
      background-color: #fff;
      border-radius: calc($border-radius / 2);
      box-shadow: 0 0 0.5px rgba(0, 0, 0, 0.1);
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      padding: 3px 5.5px;

      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #fff;
        clear: both;
        content: '';
        height: 0;
        left: 50%;
        margin: -1px 0 0 -6px;
        position: absolute;
        top: 100%;
        width: 0;
      }

      &.status-open {
        background-color: map-get($colors, 'primary');

        &:after {
          border-top-color: map-get($colors, 'primary');
        }
      }

      &.status-pending {
        background-color: map-get($colors, 'secondary');

        &:after {
          border-top-color: map-get($colors, 'secondary');
        }
      }

      &.status-sold {
        background-color: map-get($colors, 'danger');

        &:after {
          border-top-color: map-get($colors, 'danger');
        }
      }
    }

    .item-preview {
      background-color: #000;
      background-size: cover;
      border-radius: $border-radius;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
      color: #fff;
      flex-direction: column;
      font-size: 15px;
      font-weight: 500;
      height: 170px;
      justify-content: flex-end;
      left: -10px;
      overflow: hidden;
      padding: 10px;
      position: absolute;
      top: 30px;
      width: 250px;
      z-index: 100;

      img {
        height: 100%;
        left: 0;
        object-fit: cover;
        opacity: 0.9;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }

      .item-topbar {
        align-items: flex-start;
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin: 0 0 5px;

        .item-status {
          font-size: 12px;
          margin: 3px 0 0 6px;
        }
      }

      .item-price {
        font-size: 20px;
        margin: 0 0 4px;
      }

      .item-bottom {
        display: flex;

        .item-baths {
          margin: 0 7.5px;
        }
      }
    }

    &.top {
      .item-preview {
        bottom: 30px;
        top: auto;
      }

      .price-tag:after {
        display: none;
      }
    }

    &.left .item-preview {
      left: -10px;
    }

    &.right .item-preview {
      right: -10px;
      left: auto;
    }

    &.center .item-preview {
      left: 50%;
      margin-left: -125px;
    }

    &.bottom .item-preview {
      bottom: auto;
      top: 30px;
    }
  }
}

.blur-map {
  backdrop-filter: blur(2px);
  height: 100%;
  left: 0 !important;
  position: absolute;
  top: 0 !important;
  width: 100%;
}

.blur-map-message {
  left: 50%;
  margin: -25px 0 0 -70px;
  position: absolute;
  top: 50%;
  width: 140px;
  z-index: 2;
}

/**
 * Tables
 */
.custom-table-wrapper {
  border-radius: $border-radius;
  border: 1px solid map-get($colors, 'border');
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    border-collapse: collapse;
    font-size: 14px;
    min-width: 800px;
    width: 100%;

    thead {
      tr {
        background-color: map-get($colors, 'light');
        border-bottom: 1px solid map-get($colors, 'border');
        border-radius: 10px;
        font-weight: 500;
        text-transform: uppercase;

        th {
          padding: 10px;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid map-get($colors, 'border-light');

        &:nth-child(odd) {
          background-color: #ffffff;
        }

        td {
          padding: 10px 10px;

          a {
            color: map-get($colors, 'primary');
            text-decoration: underline;

            &:hover {
              color: darken(map-get($colors, 'primary'), 5%);
            }
          }
        }
      }
    }
  }
}

/**
 * Number card
 */
.number-card {
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 1rem 2rem 1rem 1rem;

  .title-icon {
    color: lighten(map-get($colors, 'text-gray'), 25%);
    display: flex;

    ng-icon {
      margin-right: 0.5rem;
    }
  }

  h4 {
    color: lighten(map-get($colors, 'text-gray'), 25%);
    font-size: 1rem;
    line-height: 1;
    margin: 0 0 1.25rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
  }
}
